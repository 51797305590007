import React from 'react';
import './App.css';

export default function NoPage() {
  return (
    <div className="Body_No_Page">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>Sorry, the page you are looking for does not exist.</p>
        <a href="/" >Go to Home</a>
    </div>
  );
}
