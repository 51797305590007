/*for the 2022 International Waterski and Wakeboard Federation’s World Water Ski Show Championships, I was asked to create an app for them to use for the sound effects for their shows. For the app I created a toggle to switch between English and German, that way if someone was not proficient in English they could still use the app.*/
/*https://apps.apple.com/us/app/waterski-show-team-germany/id1645021918*/

import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faAndroid } from "@fortawesome/free-brands-svg-icons";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { faFileCode } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { faAppStore } from "@fortawesome/free-brands-svg-icons";
import Germany1 from "./Germany1.jpg";
import Germany2 from "./Germany2.jpg";
export default function Germany() {
   return (
    <div className="Body">
        <h2>Waterski Show Team Germany Sound Effect App</h2>
        <br></br>
        <h3>2022, Addison Fenrick</h3>
        <br></br>
        <p>
            For the 2022 International Waterski and Wakeboard Federation’s World Water Ski Show Championships, I was asked to create an app for them to use for the sound effects for their shows. For the app I created a toggle to switch between English and German, that way if someone was not proficient in English they could still use the app.
        </p>
        <br></br>
        <br></br>
        <h3>Screenshots</h3>
        <br></br>        
        <div className="screenshots">
        <img src={Germany1} alt="" className="screenshot-image"/>
        <img src={Germany2} alt="" className="screenshot-image"/>
        </div>
        <br></br>        
        <h3>Technologies Used</h3>
        <ul>
            <li>Swift</li>
        </ul>
        <br></br>
        <a href="https://apps.apple.com/us/app/waterski-show-team-germany/id1645021918" target="_blank" rel="noopener noreferrer" className="app-link">
                <div className="app-text">
                    <FontAwesomeIcon icon={faAppStore} size="2x" />
                    <p>
                    {"Download on the App Store"}
                    </p>
                </div>
        </a>
        <br></br>
    </div>
    );
}