import logo from './logo.png';
import NavBar from './Navbar';

export default function Classes() {
    return (
      <div className="App">
            <body className="Body">
                <h1>My Classes</h1>
                <br></br>
                <div>
                    <h3>
                    Spring 2025                 
                    </h3>
                    <h5>
                        <ul>
                            <li>
                            Introduction to Network Security
                            </li>
                            <li>
                            IT Security Management
                            </li>
                            <li>
                            Object Oriented Analysis and Design
                            </li>
                            <li>
                            Computer Science Project II
                            </li>
                            <li>
                            Cybersecurity Project II
                            </li>
                        </ul>
                    </h5>
                </div>
                <br></br>
                <div>
                    <h3>
                    Fall 2024                 
                    </h3>
                    <h5>
                        <ul>
                            <li>
                            Cryptography
                            </li>
                            <li>
                            Logic and Digital Design
                            </li>
                            <li>
                            Software Security
                            </li>
                            <li>
                            Computer Science Project I
                            </li>
                            <li>
                            Cybersecurity Project I
                            </li>
                        </ul>
                    </h5>
                </div>
                <br></br>
                <div>
                    <h3>
Spring 2024                 </h3>

                    <h5>
                        <ul>
                            <li>
                            Database Design and Implementation

                            </li>
                            <li>
                            Introduction to Cybersecurity

                            </li>
                            <li>
                            Intermediate Software Engineering
                            </li>
                        </ul>
                    </h5>
                </div>
                <br></br>
                <div>
                    <h3>
Fall 2023                 </h3>

                    <h5>
                        <ul>
                            <li>
                            Software Quality

                            </li>
                            <li>
                            Data Communications and Computer Networks

                            </li>
                            <li>
                            Algorithms

                            </li>
                            <li>
                            Program Language Structure
                            </li>
                        </ul>
                    </h5>
                </div>
                <br></br>
                <div>
                    <h3>
Spring 2023                 </h3>

                    <h5>
                        <ul>
                        <li>
                        Operating Systems

                            </li>
                        </ul>
                    </h5>
                </div>
                <br></br>
                <div>
                    <h3>
Fall 2022                 </h3>

                    <h5>
                        <ul>
                            <li>
                            Introduction to Software Engineering

                            </li>
                            <li>
                            Introduction to Computer Science

                            </li>
                            <li>
                            Data Structures

                            </li>
                        </ul>
                    </h5>
                </div>
                <br></br>
                <div>
                    <h3>
High School Courses                </h3>

                    <h5>
                        <ul>
                            <li>
                            AP Computer Science A

                            </li>
                            <li>
                            Advanced Computer Science AB(Dual credit through University of Wisconsin – Whitewater)
                            </li>
                            <li>
                            AP Computer Science Principles
                            </li>

                        </ul>
                    </h5>
                </div>
            </body>
      </div>
    );
  }
  /*
  Fall 2024
Cryptography
Logic and Digital Design
Software Security
Computer Science Project I
Cybersecurity Project I

Spring 2024
Database Design and Implementation
Introduction to Cybersecurity
Intermediate Software Engineering

Fall 2023
Software Quality
Data Communications and Computer Networks
Algorithms
Program Language Structure

Spring 2023
Operating Systems

Fall 2022
Introduction to Software Engineering
Introduction to Computer Science
Data Structures

High School Courses
AP Computer Science A
Advanced Computer Science AB(dual credit through University of Wisconsin – Whitewater)
AP Computer Science Principles
  */