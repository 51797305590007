import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";


const NavBar = () => {

  return (
    <div className="footer">
      <div className="social-links">
      <a href="https://www.linkedin.com/in/addison-fenrick/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a href="https://github.com/AddisonFenrick" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
        
      </div>
        <br></br>
        <p>Feel free to email me at <a href = 'mailto:addison@addisonfenrick.com'className='link'>addison@addisonfenrick.com.</a></p>
        <br></br>
      <p>© 2025 Addison Fenrick. All rights reserved.</p>
    </div>
  );
};

export default NavBar;
