import React from 'react';
import './App.css';
import Germany_photo from './Germany.jpg';
import { NavLink } from 'react-router-dom';

const projects = [
  { name: 'Waterski Show Team Germany Sound Effect App', link: '/Germany', image: Germany_photo },
  // { name: 'Project 2', description: 'Description of project 2', link: '', image: '' },
  // Add more projects as needed
];

export default function Projects() {
  return (
    <div className='App'>
      <div className="Body">
        <h1>My Projects</h1>
        <br></br>
        <table className="projects-table">

          <tbody>
            {projects.map((project, index) => (
              <tr key={index}>
                <td><NavLink to = "/projects/Germany" className="project-link">
                  
                <td><img src={project.image} alt={project.name} className='project-image' /></td>
                <td>{project.name}</td>

                  </NavLink></td>
              </tr>
            ))}
          </tbody>
        </table>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}