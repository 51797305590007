import logo from './logo.png';
import NavBar from './Navbar';


export default function work() {
    return (
        <div className="App">

            <body className="Body">
                <h2>
                    Work Experience
                </h2>
                <br></br>
                <div>
                    <h3>
                        John Deere
                    </h3>
                    <h5>
                        Part Time Student Software Engineer / May 2024 – Present
                    </h5>
                    <h6>
                        <ul>
                            <li>Develop and update python scripts that are used for VR software that interfaced with multiple different software programs and hardware products.</li>
                            <li>Maintain and update multiple C# applications.</li>
                            <li>Run customer and serviceability advocacy groups utilizing VR.</li>
                            <li>Working 20 hours a week while full time in school.</li>
                        </ul>
                    </h6>
                </div>
                <br></br>
                <div>
                    <h3>
                        Janesville Ice Arena
                    </h3>
                    <h5>
                        Ice Arena Supervisor / January 2023 – June 2024
                    </h5>
                    <h6>
                        <ul>
                            <li>Operate the ice resurfacer to prepare the ice sheet.</li>
                            <li>Practice proper cash handling procedures and register operation.</li>
                        </ul>
                    </h6>
                    <h5>
                        Ice Arena Skate Host / February 2022 – June 2024 
                    </h5>
                    <h6>
                        <ul>
                            <li>Handle cash transactions in POS software. </li>
                            <li>Ensure diligent patron control and consistent rule enforcement.</li>
                        </ul>
                    </h6>
                </div>
                <br></br>
                <div>
                    <h3>
                        University of Wisconsin–Platteville Pioneer Activity Center
                    </h3>
                    <h5>
                    Facility Supervisor / November 2023 – May 2024
                    </h5>
                    <h6>
                        <ul>
                            <li>Oversee building opening and closing procedures, ensuring proper setup and security.</li>
                            <li>Conduct regular walk-throughs, manage facilities, and address potential safety concerns.</li>
                            <li>Supervise and train staff and maintain equipment setups across various facilities.</li>
                        </ul>
                    </h6>
                    <h5>
                    Customer Service Attendant / August 2023 – May 2024 
                    </h5>
                    <h6>
                        <ul>
                            <li>Validated membership for the Pioneer Activity Center.</li>
                            <li>Handled cash transactions in POS software.</li>
                        </ul>
                    </h6>
                </div>
                {/* <div>
                    <h3>
                        Bessie’s Diner
                    </h3>
                    <h5>
                    Line Cook / May 2023 – August 2023
                    </h5>

                    <h5>
                    Server / May 2023 – August 2023
                    </h5>


                </div> */}
            </body>
        </div>
    );
}

  /*
John Deere
Part Time Student Software Engineer / May 2024 – Present

City of Janesville, WI
Ice Arena Supervisor / January 2023 – June 2024

Operate the ice resurfacer to prepare the ice sheet.
Practice proper cash handling procedures and register operation.
Ice Arena Skate Host / February 2022 – June 2024 

Handle cash transactions in POS software. 
Ensure diligent patron control and consistent rule enforcement.
University of Wisconsin – Platteville Pioneer Activity Center
Facility Supervisor / November 2023 – May 2024

Oversee building opening and closing procedures, ensuring proper setup and security.
Conduct regular walk-throughs, manage facilities, and address potential safety concerns.
Supervise and train staff and maintain equipment setups across various facilities.
Customer Service Attendant / August 2023 – May 2024 

Validated membership for the Pioneer Activity Center.
Handled cash transactions in POS software.


Bessie’s Diner
Line Cook / April 2022 – August 2022, May 2023 – August 2023

Planned the timing of food production to coordinate with meal serving hours, preserving food quality.
Collaborated with other members of the kitchen staff to ensure efficient service.
Maintained positive working relationships with team members, promoting a productive kitchen environment.
Utilized grills, fryers, and ovens to heat food to appropriate serving temperatures. Organized ingredients into fridges and bins.


Server / March 2022 – August 2022, May 2023 – August 2023

Exercised focus and concentration to manage tables and remember orders.
Monitored food and beverage levels to satisfy customer needs, and communicated special dietary needs or requests to the kitchen, delivering personalized meal options.

Busser / June 2021 – March 2022

Arranged the dining room according to expected customer numbers.
Collaborated with staff and teams to develop professional relationships with coworkers and build personal networks.


  */
