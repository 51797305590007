// App.js
import React from 'react';
import { HashRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import NavBar from './Pages/Navbar';
import About from './Pages/About';
import Work from './Pages/Work';
import Classes from './Pages/Classes';
import Projects from './Pages/Projects';
import './Pages/App.css';
import logo from './Pages/logo.png';
import NoPage from './Pages/noPage';
import Footer from './Pages/Footer';
import Germany from './Pages/Projects/germany';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
        <NavLink to='/'>
            <img src={logo} alt='Addison Fenrick' className='logo' />
          </NavLink>
          <NavBar />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/work" element={<Work />} />
            <Route path="/classes" element={<Classes />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/germany" element={<Germany/>} />
            <Route path="/*" element={<NoPage />} />
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

export default App;
