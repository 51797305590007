import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const NavBar = () => {
  const [isResponsive, setIsResponsive] = useState(false);

  const handleToggle = () => {
    setIsResponsive(!isResponsive);
  };

  const handleLinkClick = () => {
    setIsResponsive(false);
  };

  const navClass = isResponsive ? "topnav responsive" : "topnav";
  
  return (
    <div className={navClass} id="myTopnav">
      <NavLink exact to="/" activeClassName="active" onClick={handleLinkClick}>About Me</NavLink>
      <NavLink to="/work" activeClassName="active" onClick={handleLinkClick}>Work Experience</NavLink>
      <NavLink to="/classes" activeClassName="active" onClick={handleLinkClick}>Courses I Have Taken</NavLink>
      <NavLink to="/projects" activeClassName="active" onClick={handleLinkClick}>Projects</NavLink>
      
      <a href="#/" className="icon" onClick={handleToggle}>
        <FontAwesomeIcon icon={faBars} />
      </a>
    </div>
  );
};

export default NavBar;
